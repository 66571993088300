import { CapacityCommitment } from '../../store/CapacityCommitment'
import { StakeInfo } from '../Stake'

import { CapacityCommitmentInformation } from './styled'

export function StakeInfoCard({
  capacityCommitment,
}: {
  capacityCommitment: CapacityCommitment
}) {
  return (
    <CapacityCommitmentInformation>
      <StakeInfo capacityCommitment={capacityCommitment} />
    </CapacityCommitmentInformation>
  )
}
