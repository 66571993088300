import { useEffect, useState } from 'react'
import { DealExplorerClient, StakerClient } from '@fluencelabs/deal-ts-clients'

import { SUBGRAPH_URL } from '../constants/config'
import { createContracts } from '../utils/createDealClient'

export const ratioToPercentFormatter = (ratio: number) => {
  return (ratio * 100).toString()
}

let cached: StakerClient
export async function setupClient() {
  if (cached) return cached

  cached = await StakerClient.create(createContracts(), SUBGRAPH_URL)

  return cached
}

let cachedDeal: DealExplorerClient
export async function setupDealClient() {
  if (cachedDeal) return cachedDeal

  cachedDeal = await DealExplorerClient.create(createContracts(), SUBGRAPH_URL)

  return cachedDeal
}

export const useClient = () => {
  const [client, setClient] = useState<StakerClient>()

  useEffect(() => {
    ;(async () => {
      const stakerClient = await setupClient()
      setClient(stakerClient)
    })()
  }, [])

  return client
}
