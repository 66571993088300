import { Entity } from '@rest-hooks/rest'

export class CapacityCommitmentBasic extends Entity {
  readonly id!: string
  readonly failedEpoch!: number
  readonly startEpoch!: number
  readonly coolDownPeriod!: number
  readonly availableToWithdraw!: number

  pk() {
    return this.id.toLowerCase()
  }

  get isAvailableToWithdraw() {
    return this.availableToWithdraw - Date.now() <= 0
  }

  static key = 'CapacityCommitmentBasic'
}
