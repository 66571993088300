import formatFTLBalance from '../utils/formatFTLBalance'

import { Text, TextWithBadge } from './Text'
import { TokenBadge } from './TokenBadge'

const sizes = {
  small: 12,
  medium: 18,
  big: 20,
}

export default function TokenBalance({
  balance,
  size = 'medium',
}: {
  size?: 'small' | 'medium' | 'big'
  balance: bigint | string
}) {
  return (
    <TextWithBadge>
      <Text size={sizes[size]}>{formatFTLBalance(balance)}</Text>
      <TokenBadge bg="purpleGradient">
        <Text size={10} weight={800} color="white">
          FLT
        </Text>
      </TokenBadge>
    </TextWithBadge>
  )
}
