import { Contracts, IMulticall__factory } from '@fluencelabs/deal-ts-clients'
import { Endpoint } from '@rest-hooks/rest'
import { ContractTransaction, JsonRpcSigner } from 'ethers'

import chunk from '../utils/chunk'
import { renderJsonString } from '../utils/renderJsonString'

import { CapacityCommitment } from './CapacityCommitment'
import { DEPLOYMENT } from '../constants/config'

async function setupMultiCall({
  signer,
  address,
}: {
  address: string
  signer: JsonRpcSigner
}) {
  const { multicall } = IMulticall__factory.connect(address, signer)

  return multicall
}

const BATCH_SIZE = 10

export const withdrawRewardsCapacityCommitment = new Endpoint(
  async ({
    capacityCommitments,
    signer,
  }: {
    capacityCommitments: CapacityCommitment[]
    signer: JsonRpcSigner
  }) => {
    const contracts = new Contracts(signer, DEPLOYMENT)

    const populatedTxs = await Promise.all(
      capacityCommitments.map((commitment) =>
        contracts.diamond.withdrawReward.populateTransaction(commitment.id, {
          maxPriorityFeePerGas: 0,
        }),
      ),
    )

    const multiCall = await setupMultiCall({
      signer,
      address: populatedTxs[0].to,
    })

    const receipts = []

    for (const txs of chunk<ContractTransaction>(populatedTxs, BATCH_SIZE)) {
      const populatedTsxData = txs.map((tx) => tx.data)

      const tx = await multiCall(populatedTsxData)
      const response = await tx.wait()

      receipts.push(response)
    }

    // TODO: update only succeeded CC
    return capacityCommitments.map((cc) => ({
      id: cc.id,
      rewards: {
        inVesting: Number(cc.rewards?.inVesting),
        claimed:
          Number(cc.rewards?.claimed ?? 0n) +
          Number(cc.rewards?.availableToClaim ?? 0n),
        availableToClaim: 0,
      },
    }))
  },
  {
    key: (params: unknown) =>
      `withdrawRewardsCapacityCommitment ${renderJsonString(params)}`,
    name: 'withdrawRewardsCapacityCommitment',
    schema: [CapacityCommitment],
  },
)
