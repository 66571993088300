import { useController } from '@rest-hooks/react'
import * as Sentry from '@sentry/react'
import { JsonRpcSigner } from 'ethers'

import { CapacityCommitment } from '../../store/CapacityCommitment'
import { withdrawRewardsCapacityCommitment } from '../../store/withdrawRewardsCapacityCommitment'
import { CommitmentsWithRewards } from '../CapacityCommitment/CommitmentsWithRewards'
import HintedText from '../HintedText'
import { WithdrawModal } from '../Modals/WithdrawModal'
import { Text } from '../Text'
import TokenBalance from '../TokenBalance'
import { WithdrawStatus } from '../WithdrawModalProvider'

import {
  InformationRecord,
  RowInfoContainer,
  WithdrawModalContent,
} from './styled'

function CapacityWithTotalReward({
  count,
  reward,
}: {
  count: number
  reward: bigint
}) {
  return (
    <RowInfoContainer>
      <InformationRecord>
        <HintedText text="number of ccs" />
        <Text size={12}>{count}</Text>
      </InformationRecord>
      <InformationRecord>
        <HintedText text="rewards" />
        <TokenBalance size="small" balance={reward} />
      </InformationRecord>
    </RowInfoContainer>
  )
}

export const WithdrawRewardMainModal = ({
  capacityCommitments,
  onClose,
  signer,
  title,
  onChangeStatus,
  onSuccess,
}: {
  capacityCommitments: CapacityCommitment[]
  onClose: () => void
  signer: JsonRpcSigner
  onChangeStatus: (status: WithdrawStatus) => void
  title?: string
  onSuccess: () => void
}) => {
  const ctrl = useController()

  async function onWithdraw() {
    try {
      onChangeStatus('pending')
      await ctrl.fetch(withdrawRewardsCapacityCommitment, {
        capacityCommitments: capacityCommitments as CapacityCommitment[],
        signer,
      })
      onChangeStatus('success')
      onSuccess()
    } catch (e) {
      Sentry.captureException(e)
      console.error(e)
      onChangeStatus('failed')
    }
  }

  return (
    <WithdrawModal
      title={
        <Text size={20} color="black900">
          {title}
        </Text>
      }
      isVisible
      onClose={onClose}
      onSubmit={onWithdraw}
      contentMinHeight={capacityCommitments.length > 1 ? '35vh' : '70px'}
    >
      <WithdrawModalContent>
        {capacityCommitments.length > 1 && (
          <CapacityWithTotalReward
            count={capacityCommitments.length}
            reward={capacityCommitments.reduce(
              (total, cc) => total + (cc.rewards?.availableToClaim ?? 0n),
              0n,
            )}
          />
        )}
        <CommitmentsWithRewards capacityCommitments={capacityCommitments} />
      </WithdrawModalContent>
    </WithdrawModal>
  )
}
