import React, { Suspense, useState } from 'react'
import styled from '@emotion/styled'
import { OrderType } from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/filters'
import { RewardsStatistics } from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/schemes'
import {
  CapacityCommitmentsDashboardFilter,
  CapacityCommitmentsDashboardOrderBy,
} from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/stakerFilters'
import { StatusStatistics } from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/stakerSchemes'
import { useController, useLive } from '@rest-hooks/react'
import { useAccount } from 'wagmi'

import { Information } from '../../components'
import { Collateral } from '../../components/CapacityCommitment/Collateral'
import { CUsStats } from '../../components/CapacityCommitment/CUsStats'
import { Rewards } from '../../components/CapacityCommitment/Rewards'
import { CapacityCommitmentContentBlock } from '../../components/CapacityCommitment/styled'
import { Space } from '../../components/Space'
import { TableBody } from '../../components/Table'
import { Text } from '../../components/Text'
import { WithdrawCollateralModals } from '../../components/Withdraw/WithdrawCollateralModals'
import { WithdrawRewardModals } from '../../components/Withdraw/WithdrawRewardModals'
import WithdrawModalProvider from '../../components/WithdrawModalProvider'
import { useFilters } from '../../hooks/useFilters'

import { getCapacityCommitmentsDashboard } from '../../store/getCapacityCommitmentsDashboard'

export const ROW_TEMPLATE_DASHBOARD = [
  [
    [
      'minmax(200px, 1fr)',
      'minmax(250px, 1fr)',
      'minmax(120px, 1fr)',
      'minmax(150px, 1fr)',
      'minmax(80px, 1fr)',
      '30px',
    ],
    ['40px'],
  ],
  [['150px', '150px'], ['40px']],
]

import { CapacityCommitmentRow } from '../../components/CapacityCommitment'

import {
  CapacityCommitmentSort,
  CapacityCommitmentTable,
} from './CapacitiesTable'

export const DashboardContent: React.FC = () => {
  const { address } = useAccount()
  const [filters, setFilter] = useFilters<CapacityCommitmentsDashboardFilter>({
    statuses: ['waitStart', 'active', 'inactive', 'failed', 'removed'],
  })

  const [order, setOrder] = useState<CapacityCommitmentSort>('createdAt:desc')
  const [orderBy, orderType] = order.split(':') as [
    CapacityCommitmentsDashboardOrderBy,
    OrderType,
  ]

  const { capacityCommitments, statusSummary, rewardsSummary } = useLive(
    getCapacityCommitmentsDashboard,
    {
      stakerId: address,
      filters,
      orderBy,
      orderType,
    },
  )

  const ctrl = useController()
  const invalidateDashboard = () => {
    ctrl.invalidate(getCapacityCommitmentsDashboard, {
      stakerId: address,
      filters,
      orderBy,
      orderType,
    })
  }

  return (
    <WithdrawModalProvider>
      <Information
        capacityCommitments={capacityCommitments}
        statusSummary={statusSummary as StatusStatistics}
        rewardsSummary={rewardsSummary as RewardsStatistics}
      />
      <Space height="32px" />
      <Header>
        <Text size={32}>Capacity commitment</Text>
      </Header>
      <CapacityCommitmentTable
        columns={ROW_TEMPLATE_DASHBOARD}
        orderBy={orderBy}
        orderType={orderType}
        filters={filters}
        setFilter={setFilter}
        setOrder={setOrder}
        capacityCommitments={capacityCommitments}
        renderRow={({ capacityCommitment, onExpand, columns }) => (
          <CapacityCommitmentRow
            key={capacityCommitment.id}
            capacityCommitment={capacityCommitment}
            toggle={onExpand}
            columns={columns}
          >
            <CapacityCommitmentContentBlock>
              <CUsStats capacityCommitment={capacityCommitment} />
              <Collateral capacityCommitment={capacityCommitment} />
              <Rewards capacityCommitment={capacityCommitment} />
            </CapacityCommitmentContentBlock>
          </CapacityCommitmentRow>
        )}
      />
      <WithdrawCollateralModals />
      <WithdrawRewardModals onSuccess={invalidateDashboard} />
    </WithdrawModalProvider>
  )
}

export const Dashboard = () => {
  return (
    <Suspense
      fallback={
        <>
          <Information />
          <Space height="32px" />
          <Header>
            <Text size={32}>Capacity commitment</Text>
          </Header>
          <TableBody skeletonCount={10} skeletonHeight={40} isLoading />
        </>
      }
    >
      <DashboardContent />
    </Suspense>
  )
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
