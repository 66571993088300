import { formatUnits } from 'viem'

import { CHAIN } from '../constants/config.ts'

export default function formatTokenUnits(
  value: bigint,
  decimals?: number | bigint,
  maxDecimalDigits?: number,
) {
  const formattedValue = formatUnits(value, Number(decimals))

  let roundedValue = parseFloat(formattedValue)

  if (maxDecimalDigits !== undefined) {
    const factor = Math.pow(10, maxDecimalDigits)
    roundedValue = Math.floor(roundedValue * factor) / factor
  }

  return roundedValue.toString()
}

export const formatNativeTokenValue = (value: bigint, maxDecimalDigits = 2) => {
  return formatTokenUnits(
    value,
    CHAIN.nativeCurrency.decimals,
    maxDecimalDigits,
  )
}

export const ratioToPercentFormatter = (ratio: number) => {
  return (ratio * 100).toString()
}
