import { A } from '../../components/A'
import { Copyable } from '../../components/Copyable'
import {
  TransactionStatusBadge,
  TransactionStatusContainer,
  TransactionStatusTextContainer,
} from '../../components/Modals/styled'
import { TransactionStatusModal } from '../../components/Modals/TransactionStatusModal'
import { Text } from '../../components/Text'
import TokenBalance from '../../components/TokenBalance'
import { getShortAddress } from '../../utils/getShortAddress'

import { BLOCKSCOUT_URL } from '../../constants/config'

export default function TransactionSuccessModal({
  id,
  onClose,
  collateral,
  transactionHash,
}: {
  id: string
  collateral: bigint
  onClose: () => void
  transactionHash?: string
}) {
  return (
    <TransactionStatusModal type="success" isVisible onClose={onClose}>
      <TransactionStatusContainer>
        <TransactionStatusTextContainer>
          <Text size={20} color="grey500">
            You staked
            <TransactionStatusBadge>
              <TokenBalance size="big" balance={collateral} />
            </TransactionStatusBadge>
            for&nbsp;
            <Copyable value={id}>
              <Text size={20}>{getShortAddress(id || '', 12)}</Text>
            </Copyable>
            &nbsp;capacity commitment
          </Text>
        </TransactionStatusTextContainer>
        {transactionHash && (
          <A href={`${BLOCKSCOUT_URL}/tx/${transactionHash}`}>
            <Text size={14} color="grey500">
              Tx: {getShortAddress(transactionHash || '', 12)}
            </Text>
          </A>
        )}
        <Text color="grey500" size={14}>
          You can interact with it in your dashboar
        </Text>
      </TransactionStatusContainer>
    </TransactionStatusModal>
  )
}
