import { useContext } from 'react'

import { CapacityCommitment } from '../../store/CapacityCommitment'
import HintedText from '../HintedText'
import { SignerContext } from '../SignerProvider'
import { Text } from '../Text'
import TokenBalance from '../TokenBalance'
import WithdrawRewardButton from '../Withdraw/WithdrawRewardButton'

import { ActionButtonContainer } from './styled'
import { CapacityCommitmentInformation, InformationRow } from './styled'

export function Rewards({
  capacityCommitment,
}: {
  capacityCommitment: CapacityCommitment
}) {
  const { signer } = useContext(SignerContext)

  if (!capacityCommitment.rewards) return null

  return (
    <CapacityCommitmentInformation>
      <Text size={16}>Rewards</Text>
      <InformationRow>
        <HintedText
          text="In vesting"
          hint="Earned rewards that are locked and will be vested later"
        />
        <TokenBalance balance={capacityCommitment.rewards.inVesting} />
      </InformationRow>
      <InformationRow>
        <HintedText
          text="Available to claim"
          hint="Rewards that can be withdrawn immediately"
        />
        <TokenBalance balance={capacityCommitment.rewards.availableToClaim} />
      </InformationRow>
      <InformationRow>
        <HintedText
          text="Total claimed"
          hint="Rewards already withdrawn.
Note: If the provider initiates withdrawal first, rewards will be automatically sent to the staker's wallet"
        />
        <TokenBalance balance={capacityCommitment.rewards.claimed} />
      </InformationRow>
      {signer && (
        <ActionButtonContainer>
          <WithdrawRewardButton
            title="You are about to withdraw reward available to claim from 
              your capacity commitment"
            capacityCommitments={[capacityCommitment]}
          >
            Withdraw rewards
          </WithdrawRewardButton>
        </ActionButtonContainer>
      )}
    </CapacityCommitmentInformation>
  )
}
