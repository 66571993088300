import React, { ReactNode, useCallback } from 'react'

import { RowBlock } from '../../components/Table'

import { CapacityCommitment } from '../../store/CapacityCommitment'

import { CapacityCommitmentCard } from './CapacityCommitmentCard'
import { CapacityCommitmentLine } from './CapacityCommitmentLine'
import { AccordionContent, RowHeader, StyledAccordionItem } from './styled'

interface CapacityCommitmentRowProps {
  capacityCommitment: CapacityCommitment
  toggle: (id: string) => void
  children: ReactNode
  columns: string[][][]
  staking?: boolean
}

export const CapacityCommitmentRow: React.FC<CapacityCommitmentRowProps> = ({
  toggle,
  children,
  capacityCommitment,
  columns,
  staking,
}) => {
  const handleToggle = useCallback(
    () => toggle(capacityCommitment.id),
    [toggle, capacityCommitment.id],
  )
  const handleOpen = (e: React.MouseEvent) => {
    e.stopPropagation()
    handleToggle()
  }

  return (
    <StyledAccordionItem value={capacityCommitment.id}>
      <RowBlock>
        <RowHeader asChild onClick={handleOpen}>
          <div>
            <CapacityCommitmentLine
              columns={columns}
              capacityCommitment={capacityCommitment}
              staking={staking}
              onExpand={handleToggle}
            />
            <CapacityCommitmentCard
              capacityCommitment={capacityCommitment}
              staking={staking}
              onExpand={handleToggle}
            />
          </div>
        </RowHeader>
        <AccordionContent>{children}</AccordionContent>
      </RowBlock>
    </StyledAccordionItem>
  )
}
