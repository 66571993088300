import { StakerClient } from '@fluencelabs/deal-ts-clients'
import { OrderType } from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/filters'
import {
  CapacityCommitmentsDashboardFilter,
  CapacityCommitmentsDashboardOrderBy,
} from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/stakerFilters'
import { Endpoint, schema } from '@rest-hooks/rest'
import * as Sentry from '@sentry/react'

import { setupClient } from '../hooks'
import { renderJsonString } from '../utils/renderJsonString'

import { CapacityCommitment } from './CapacityCommitment'

export const getCapacityCommitmentsDashboard = new Endpoint(
  async ({
    stakerId,
    filters,
    orderType,
    orderBy,
  }: {
    stakerId?: string
    orderBy?: CapacityCommitmentsDashboardOrderBy
    filters?: CapacityCommitmentsDashboardFilter
    orderType?: OrderType
    client?: StakerClient
  }) => {
    const defaultValue = {
      capacityCommitments: [],
      statusSummary: {
        active: 0,
        inactive: 0,
        failed: 0,
        waitStart: 0,
        removed: 0,
      },
      rewardsSummary: {
        inVesting: 0,
        availableToClaim: 0,
        claimed: 0,
      },
    }
    try {
      const client = await setupClient()
      const data = await client.getCapacityCommitmentsDashboard(
        stakerId,
        filters,
        orderBy,
        orderType,
      )

      return {
        capacityCommitments: data.data.map((cc) => ({
          ...cc,
          providerInfo: {
            address: cc.providerInfo.address,
            name: cc.providerInfo.name,
          },
        })),
        statusSummary: data.statusSummary,
        rewardsSummary: data.rewardsSummary,
      }
    } catch (e) {
      Sentry.captureException(e)
      console.error(e)
      return defaultValue
    }
  },
  {
    key: (params: unknown) =>
      `getCapacityCommitmentsDashboard ${renderJsonString(params)}`,
    name: 'getCapacityCommitmentsDashboard',
    schema: {
      capacityCommitments: [CapacityCommitment],
      statusSummary: new schema.Object({}),
      rewardsSummary: new schema.Object({}),
    },
    pollFrequency: 60000,
  },
)
