import { Endpoint } from '@rest-hooks/rest'
import * as Sentry from '@sentry/react'

import { setupClient } from '../hooks'
import { createContracts } from '../utils/createDealClient'
import { renderJsonString } from '../utils/renderJsonString'

import { CapacityCommitmentBasic } from './CapacityCommitmentBasic'

export const getCapacityCommitmentCoolDown = new Endpoint(
  async ({ id }: { id: string }) => {
    try {
      const client = await setupClient()
      const contracts = createContracts()
      const withdrawEpochsAfterFailed =
        await contracts.diamond.withdrawEpochsAfterFailed()

      const capacityCommitment = await contracts.diamond.getCommitment(id)

      const failedEpoch = Number(capacityCommitment.failedEpoch)
      const startEpoch = Number(capacityCommitment.startEpoch)

      const coolDown =
        Number(withdrawEpochsAfterFailed) * client.getEpochDuration()

      const period = coolDown * 1000

      return {
        id,
        failedEpoch,
        startEpoch,
        coolDownPeriod: period,
        availableToWithdraw:
          (client.calculateTimestamp(failedEpoch) + coolDown) * 1000,
      }
    } catch (e) {
      Sentry.captureException(e)
      console.error(e)
      return null
    }
  },
  {
    key: (params: unknown) =>
      `getCapacityCommitmentsStake ${renderJsonString(params)}`,
    name: 'getCapacityCommitmentsStake',
    schema: CapacityCommitmentBasic,
  },
)
