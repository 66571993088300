import { FC, Suspense } from 'react'
import { useCache, useSuspense } from '@rest-hooks/react'
import { formatDistanceToNowStrict } from 'date-fns'

import { getShortAddress } from '../../utils/getShortAddress'

import { CapacityCommitment } from '../../store/CapacityCommitment'
import { getCapacityCommitmentCoolDown } from '../../store/getCapacityCommitmentCoolDown'
import { Button } from '../Button'
import { Copyable } from '../Copyable'
import { Warning } from '../Modals/Warning'
import {
  ButtonsContainer,
  ModalFooter,
  WithdrawModal,
  WithdrawModalFooterProps,
} from '../Modals/WithdrawModal'
import { Text } from '../Text'
import TokenBalance from '../TokenBalance'

import {
  RowWithdrawTotal,
  RowWithdrawTotalWrapper,
  WithdrawModalTitle,
} from './styled'

const Footer: FC<WithdrawModalFooterProps & { id: string }> = ({
  handleClose,
  handleSubmit,
  id,
}) => {
  const basicCapacityCommitment = useSuspense(getCapacityCommitmentCoolDown, {
    id,
  })

  const isAvailableToWithdraw = basicCapacityCommitment.isAvailableToWithdraw

  return (
    <ModalFooter>
      {isAvailableToWithdraw ? (
        <ButtonsContainer>
          <Button variant="grey" isLarge onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="black" isLarge onClick={handleSubmit}>
            Withdraw
          </Button>
        </ButtonsContainer>
      ) : (
        <ButtonsContainer>
          <Button variant="dash" isLarge>
            Wait end of cooldown period
          </Button>
        </ButtonsContainer>
      )}
    </ModalFooter>
  )
}

export function WithdrawFromFailedCC({
  capacityCommitment,
  onWithdraw,
  onClose,
}: {
  onClose: () => void
  capacityCommitment: CapacityCommitment
  onWithdraw: () => void
}) {
  const basicCapacityCommitment = useCache(getCapacityCommitmentCoolDown, {
    id: capacityCommitment.id,
  })

  const isAvailableToWithdraw = basicCapacityCommitment?.isAvailableToWithdraw
  const shouldWait =
    !isAvailableToWithdraw &&
    basicCapacityCommitment?.coolDownPeriod &&
    basicCapacityCommitment?.coolDownPeriod > 0

  console.log(basicCapacityCommitment, shouldWait, isAvailableToWithdraw)

  return (
    <WithdrawModal
      title={
        <WithdrawModalTitle>
          <Text size={20} color="grey500">
            You are about to withdraw collateral from capacity commitment
            with&nbsp;
            <Text size={20} color="red">
              Failed
            </Text>
            &nbsp;status
          </Text>
          <Copyable value={capacityCommitment.id}>
            <Text size={12} color="grey500">
              ID: {getShortAddress(capacityCommitment.id, 24)}
            </Text>
          </Copyable>
        </WithdrawModalTitle>
      }
      // contentMinHeight={shouldWait ? '200px' : '50px'}
      isVisible
      onClose={onClose}
      onSubmit={onWithdraw}
      warning={
        shouldWait &&
        basicCapacityCommitment?.coolDownPeriod && (
          <RowWithdrawTotalWrapper>
            <Warning>
              Because you are withdrawing collateral from a FAILED commitment,
              the withdrawal period is{' '}
              {formatDistanceToNowStrict(
                Date.now() + basicCapacityCommitment?.coolDownPeriod,
              )}
              .
            </Warning>
          </RowWithdrawTotalWrapper>
        )
      }
      footer={(props) => (
        <Suspense
          fallback={
            <ModalFooter>
              <ButtonsContainer>
                <Button variant="dash" isLarge disabled>
                  Loading...
                </Button>
              </ButtonsContainer>
            </ModalFooter>
          }
        >
          <Footer id={capacityCommitment.id} {...props} />
        </Suspense>
      )}
    >
      <RowWithdrawTotalWrapper>
        <RowWithdrawTotal>
          <Text size={12} color="grey500" weight={500}>
            Collateral to withdraw
          </Text>
          <TokenBalance balance={capacityCommitment.currentCollateral ?? 0n} />
        </RowWithdrawTotal>
        {shouldWait && (
          <RowWithdrawTotal>
            <Text size={12} color="grey500" weight={500}>
              End cooldown
            </Text>
            <Text size={18}>
              ~{' '}
              {formatDistanceToNowStrict(
                basicCapacityCommitment.coolDownPeriod,
              )}
            </Text>
          </RowWithdrawTotal>
        )}
      </RowWithdrawTotalWrapper>
    </WithdrawModal>
  )
}
