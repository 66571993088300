import { OrderType } from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/filters'
import {
  CapacityCommitmentsStakeOrderBy,
  CapacityCommitmentsStakerFilters,
} from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/stakerFilters'
import { Endpoint } from '@rest-hooks/rest'
import * as Sentry from '@sentry/react'

import { setupClient } from '../hooks'
import { renderJsonString } from '../utils/renderJsonString'

import { CapacityCommitment } from './CapacityCommitment'

export const getCapacityCommitmentsStake = new Endpoint(
  async ({
    filters,
    orderType,
    orderBy,
  }: {
    orderBy: CapacityCommitmentsStakeOrderBy
    filters?: CapacityCommitmentsStakerFilters
    orderType?: OrderType
  }) => {
    const stakerClient = await setupClient()

    try {
      const data = await stakerClient.getCapacityCommitmentsStake(
        orderBy,
        filters,
        orderType,
      )

      return data.data
    } catch (e) {
      Sentry.captureException(e)
      console.error(e)
      return []
    }
  },
  {
    key: (params: unknown) =>
      `getCapacityCommitmentsStake ${renderJsonString(params)}`,
    name: 'getCapacityCommitmentsStake',
    schema: [CapacityCommitment],
    pollFrequency: 60000,
  },
)
