import { getShortAddress } from '../../utils/getShortAddress'

import { CapacityCommitment } from '../../store/CapacityCommitment'
import { Copyable } from '../Copyable'
import { WithdrawModal } from '../Modals/WithdrawModal'
import { Text } from '../Text'
import TokenBalance from '../TokenBalance'

import {
  RowWithdrawTotal,
  RowWithdrawTotalWrapper,
  WithdrawModalTitle,
} from './styled'

export function WithdrawFromCompletedCC({
  capacityCommitment,
  onClose,
  onWithdraw,
}: {
  onClose: () => void
  onWithdraw: () => void
  capacityCommitment: CapacityCommitment
}) {
  return (
    <WithdrawModal
      title={
        <WithdrawModalTitle>
          <Text size={20} color="grey500">
            You are about to withdraw collateral from capacity commitment
            with&nbsp;
            <Text
              size={20}
              color={capacityCommitment.status === 'failed' ? 'red' : 'green'}
            >
              {capacityCommitment.status === 'failed' ? 'Failed' : 'Finished'}
            </Text>
            &nbsp;status
          </Text>
          <Copyable value={capacityCommitment.id}>
            <Text size={12} color="grey500">
              ID: {getShortAddress(capacityCommitment.id, 24)}
            </Text>
          </Copyable>
        </WithdrawModalTitle>
      }
      contentMinHeight="100px"
      isVisible
      onClose={onClose}
      onSubmit={onWithdraw}
    >
      <RowWithdrawTotalWrapper>
        <RowWithdrawTotal>
          <Text size={12} color="grey500" weight={500}>
            Collateral to withdraw
          </Text>
          <TokenBalance balance={capacityCommitment.currentCollateral ?? 0n} />
        </RowWithdrawTotal>
      </RowWithdrawTotalWrapper>
    </WithdrawModal>
  )
}
