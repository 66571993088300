import { useContext } from 'react'

import { CapacityCommitment } from '../../store/CapacityCommitment'
import { Button } from '../Button'
import { UppercaseText } from '../Text'
import { WithdrawModalContext } from '../WithdrawModalProvider'

export default function WithdrawCollateralButton({
  capacityCommitment,
}: {
  capacityCommitment: CapacityCommitment
}) {
  const { data: withdrawInfo, setData: setWithdrawInfo } =
    useContext(WithdrawModalContext)

  if (
    capacityCommitment.status !== 'failed' &&
    capacityCommitment.status !== 'inactive'
  )
    return null

  async function onOpenWithdraw() {
    if (capacityCommitment.collateralWithdrawn) return
    setWithdrawInfo(
      withdrawInfo?.id === `waiting-${capacityCommitment.id}`
        ? withdrawInfo
        : {
            id: `waiting-${capacityCommitment.id}`,
            status: 'waiting',
            type: 'collateral',
            title: 'Withdraw collateral',
            capacityCommitments: [capacityCommitment],
          },
    )
  }

  return (
    <Button
      variant={capacityCommitment.collateralWithdrawn ? 'success' : 'outline'}
      onClick={onOpenWithdraw}
      disabled={capacityCommitment.collateralWithdrawn}
    >
      <UppercaseText
        size={10}
        weight={700}
        color={capacityCommitment.collateralWithdrawn ? 'green' : 'black900'}
      >
        {capacityCommitment.collateralWithdrawn
          ? 'ALREADY WITHDRAWN'
          : 'Withdraw collateral'}
      </UppercaseText>
    </Button>
  )
}
