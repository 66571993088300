import { Suspense, useContext, useEffect, useMemo, useState } from 'react'
import { OrderType } from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/filters'
import { RewardsStatistics } from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/schemes'
import {
  CapacityCommitmentsDashboardFilter,
  CapacityCommitmentsDashboardOrderBy,
  CapacityCommitmentsStakerFilters,
} from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/stakerFilters'
import { StatusStatistics } from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/stakerSchemes'
import { useController, useLive } from '@rest-hooks/react'
import { useAccount } from 'wagmi'

import { Information, TableBody, TableControlsPanel } from '../../components'
import { CapacityCommitmentRow } from '../../components/CapacityCommitment'
import { Collateral } from '../../components/CapacityCommitment/Collateral'
import { CommitmentInfo } from '../../components/CapacityCommitment/CommitmentInfo'
import { CUsStats } from '../../components/CapacityCommitment/CUsStats'
import { ProviderInfo } from '../../components/CapacityCommitment/ProviderInfo'
import { Rewards } from '../../components/CapacityCommitment/Rewards'
import { StakeInfoCard } from '../../components/CapacityCommitment/StakeInfoCard'
import {
  CapacityCommitmentContentBlock,
  ROW_TEMPLATE,
} from '../../components/CapacityCommitment/styled'
import { SignerContext } from '../../components/SignerProvider'
import { Space } from '../../components/Space'
import { StakingModals } from '../../components/Stake'
import { Switch } from '../../components/Switch'
import { WithdrawCollateralModals } from '../../components/Withdraw/WithdrawCollateralModals'
import { WithdrawRewardModals } from '../../components/Withdraw/WithdrawRewardModals'
import WithdrawModalProvider from '../../components/WithdrawModalProvider'
import { useFilters } from '../../hooks/useFilters'

import { getCapacityCommitmentsDashboard } from '../../store/getCapacityCommitmentsDashboard'
import { CapacityCommitmentTable as CapacityCommitmentTableStaking } from '../capacities/CapacitiesTable'
import { ROW_TEMPLATE_DASHBOARD } from '../dashboard'
import {
  CapacityCommitmentSort,
  CapacityCommitmentTable as CapacityCommitmentTableDashboard,
} from '../dashboard/CapacitiesTable'

export function MainPageSuspended() {
  const [stackFilters, setStakeFilter] =
    useFilters<CapacityCommitmentsStakerFilters>()
  const { signer } = useContext(SignerContext)

  const { address } = useAccount()
  const [filters, setFilter] = useFilters<CapacityCommitmentsDashboardFilter>({
    statuses: ['waitStart', 'active', 'inactive', 'failed', 'removed'],
  })

  const [order, setOrder] = useState<CapacityCommitmentSort>('createdAt:desc')
  const [orderBy, orderType] = order.split(':') as [
    CapacityCommitmentsDashboardOrderBy,
    OrderType,
  ]

  const { capacityCommitments, statusSummary, rewardsSummary } = useLive(
    getCapacityCommitmentsDashboard,
    {
      stakerId: address,
      filters,
      orderBy,
      orderType,
    },
  )

  const ctrl = useController()
  const invalidateDashboard = async () => {
    // TODO: remove delay
    await new Promise((res) => setTimeout(res, 5000))
    ctrl.invalidate(getCapacityCommitmentsDashboard, {
      stakerId: address,
      filters,
      orderBy,
      orderType,
    })
  }

  useEffect(() => {
    if (
      stackFilters.staker &&
      stackFilters.staker.toLowerCase() !== signer?.address.toLowerCase()
    )
      setStakeFilter('staker', signer?.address.toLowerCase())
  }, [stackFilters.staker, signer?.address])

  const filteredCapacityCommitments = useMemo(() => {
    const search = stackFilters.searchBy?.toLowerCase()
    if (!search?.length) return capacityCommitments

    return capacityCommitments.filter(
      (cc) =>
        cc.id?.toLowerCase() === search ||
        cc.id?.toLowerCase() === search ||
        cc.id?.toLowerCase().slice(0, search.length) === search ||
        cc.id?.toLowerCase().slice(-search.length) === search ||
        cc.providerInfo?.address?.toLowerCase() === search ||
        cc.providerInfo?.address?.toLowerCase().slice(0, search.length) ===
          search ||
        cc.providerInfo?.address?.toLowerCase().slice(-search.length) ===
          search ||
        cc.providerInfo?.name?.toLowerCase().includes(search),
    )
  }, [stackFilters.searchBy, capacityCommitments])

  return (
    <WithdrawModalProvider>
      <Information
        capacityCommitments={capacityCommitments}
        statusSummary={statusSummary as StatusStatistics}
        rewardsSummary={rewardsSummary as RewardsStatistics}
      />
      <Space height="38px" />
      <TableControlsPanel
        filters={stackFilters}
        setFilter={setStakeFilter}
        title="Available for Staking"
        searchPlaceholder="Search by Commitment ID / Provider ID / Provider name"
      />
      {signer?.address && (
        <>
          <Space height="16px" />
          <Switch
            label="Only for me"
            hint="Shows Capacity Commitments where Providers have set your wallet address as the sole possible staker"
            value={
              stackFilters.staker === undefined ? false : !!stackFilters.staker
            }
            onSwitch={(value) =>
              setStakeFilter(
                'staker',
                value ? signer?.address.toLowerCase() : undefined,
              )
            }
          />
        </>
      )}
      <Space height="24px" />
      <Suspense fallback={<TableBody isLoading />}>
        <CapacityCommitmentTableStaking
          filters={stackFilters}
          columns={ROW_TEMPLATE}
          stakerId={address}
          renderRow={({ capacityCommitment, onExpand, columns }) => (
            <CapacityCommitmentRow
              key={capacityCommitment.id}
              capacityCommitment={capacityCommitment}
              toggle={onExpand}
              columns={columns}
              staking
            >
              <CapacityCommitmentContentBlock>
                <CommitmentInfo capacityCommitment={capacityCommitment} />
                <ProviderInfo capacityCommitment={capacityCommitment} />
                <StakeInfoCard capacityCommitment={capacityCommitment} />
              </CapacityCommitmentContentBlock>
            </CapacityCommitmentRow>
          )}
        />
      </Suspense>
      <Space height="24px" />
      <TableControlsPanel
        filters={{}}
        setFilter={() => {}}
        title={
          signer?.address
            ? `Your Capacity Commitments`
            : `Staked Capacity Commitments`
        }
        showSearch={false}
      />
      <Suspense fallback={<TableBody isLoading />}>
        <CapacityCommitmentTableDashboard
          columns={ROW_TEMPLATE_DASHBOARD}
          orderBy={orderBy}
          orderType={orderType}
          filters={filters}
          setFilter={setFilter}
          setOrder={setOrder}
          capacityCommitments={filteredCapacityCommitments}
          stakerId={address}
          renderRow={({ capacityCommitment, onExpand, columns }) => (
            <CapacityCommitmentRow
              key={capacityCommitment.id}
              capacityCommitment={capacityCommitment}
              toggle={onExpand}
              columns={columns}
            >
              <CapacityCommitmentContentBlock>
                <CUsStats capacityCommitment={capacityCommitment} />
                <Collateral capacityCommitment={capacityCommitment} />
                <Rewards capacityCommitment={capacityCommitment} />
              </CapacityCommitmentContentBlock>
            </CapacityCommitmentRow>
          )}
        />
      </Suspense>
      <WithdrawCollateralModals />
      <WithdrawRewardModals onSuccess={invalidateDashboard} />
      <StakingModals onSuccess={invalidateDashboard} />
    </WithdrawModalProvider>
  )
}

export function MainPage() {
  return (
    <Suspense
      fallback={
        <>
          <Information />
          <Space height="38px" />
          <TableControlsPanel
            filters={{}}
            setFilter={() => {}}
            title="Capacity commitment"
            searchPlaceholder="Search by Commitment ID / Provider ID / Provider name"
          />
          <Space height="24px" />
          <TableBody isLoading />
          <TableBody isLoading />
        </>
      }
    >
      <MainPageSuspended />
    </Suspense>
  )
}
