import React, { ReactElement, useEffect, useState } from 'react'
import {
  CapacityCommitmentsStatusFilter,
  OrderType,
} from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/filters'
import {
  CapacityCommitmentsDashboardFilter,
  CapacityCommitmentsDashboardOrderBy,
} from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/stakerFilters'
import * as Accordion from '@radix-ui/react-accordion'

import { Flex } from '../../components/Flex'
import { MessageBlock, MessageContainer } from '../../components/Message'
import { Select } from '../../components/Select'
import {
  StyledHint,
  TableBody,
  TableColumnTitle,
  TableColumnTitleWithSort,
  TableHeader,
  TableResponsive,
  TableResponsiveWrapper,
} from '../../components/Table'
import { TableFooter } from '../../components/Table/TableFooter'
import { Text, UppercaseText } from '../../components/Text'
import { usePagination } from '../../hooks'
import { SetFilter } from '../../hooks/useFilters'
import { usePrevious } from '../../hooks/usePrevious'

import { STATUS_NAMES } from '../../constants/statusesMap'
import { CapacityCommitment } from '../../store/CapacityCommitment'

import { StatusContainer } from './styled'

const CAPACITY_COMMITMENTS_PER_PAGE = 5

export type CapacityCommitmentSort =
  `${CapacityCommitmentsDashboardOrderBy}:${OrderType}`

const STATUS_ITEMS: {
  value: CapacityCommitmentsStatusFilter | 'all'
  label: string
}[] = [
  { value: 'all', label: 'All' },
  { value: 'active', label: STATUS_NAMES['active'] },
  { value: 'inactive', label: STATUS_NAMES['inactive'] },
  { value: 'failed', label: STATUS_NAMES['failed'] },
  { value: 'removed', label: STATUS_NAMES['removed'] },
  { value: 'waitStart', label: STATUS_NAMES['waitStart'] },
]

export const CapacityCommitmentTable: React.FC<{
  filters: CapacityCommitmentsDashboardFilter
  setFilter: SetFilter<CapacityCommitmentsDashboardFilter>
  capacityCommitments?: CapacityCommitment[]
  setOrder: (order: CapacityCommitmentSort) => void
  orderType: OrderType
  orderBy: CapacityCommitmentsDashboardOrderBy
  isLoading?: boolean
  columns: string[][][]
  stakerId?: string
  renderRow: (params: {
    capacityCommitment: CapacityCommitment
    onExpand: (id: string) => void
    columns: string[][][]
  }) => ReactElement
}> = ({
  setFilter,
  capacityCommitments,
  setOrder,
  isLoading,
  filters,
  orderType,
  orderBy,
  renderRow,
  columns,
  stakerId,
}) => {
  const [ccPerPage, setCCPerPage] = useState(CAPACITY_COMMITMENTS_PER_PAGE)
  const [isAccordionOpen, setIsAccordionOpen] = useState<string[]>([])

  const { page, selectPage, limit, offset, getTotalPages } =
    usePagination(ccPerPage)

  const prevStakerId = usePrevious(stakerId)
  const prevFilters = usePrevious(filters)
  const prevCCPerPage = usePrevious(ccPerPage)
  useEffect(() => {
    if (
      prevStakerId !== stakerId ||
      prevFilters !== filters ||
      prevCCPerPage !== ccPerPage
    )
      selectPage(1)
  }, [
    stakerId,
    prevStakerId,
    selectPage,
    prevFilters,
    filters,
    prevCCPerPage,
    ccPerPage,
  ])

  const hasNextPage = capacityCommitments && capacityCommitments.length > limit
  const pageCapacityCommitments =
    capacityCommitments && capacityCommitments.slice(offset, offset + limit)

  const onExpand = (id: string) => {
    if (isAccordionOpen.includes(id)) {
      return setIsAccordionOpen((arr) => arr.filter((v) => v !== id))
    }

    setIsAccordionOpen([...isAccordionOpen, id])
  }

  const handleSort = (
    key: CapacityCommitmentsDashboardOrderBy,
    order: OrderType,
  ) => {
    setOrder(`${key}:${order}`)
  }

  const handleSetStatus = (value: CapacityCommitmentsStatusFilter | 'all') => {
    const statuses =
      value === 'all'
        ? ([
            'waitStart',
            'active',
            'inactive',
            'failed',
            'removed',
          ] as CapacityCommitmentsStatusFilter[])
        : [value]
    setFilter('statuses', statuses)
  }

  let statusFilter: CapacityCommitmentsStatusFilter | 'all' = 'all'
  if (filters?.statuses && filters.statuses[0]) {
    statusFilter = filters?.statuses.length > 1 ? 'all' : filters?.statuses[0]
  }

  return (
    <Flex gap="24px" flexDirection="column" mt="24px" mb="24px">
      <StatusContainer>
        <UppercaseText size={10} weight={600} color="grey400">
          Status
        </UppercaseText>
        <Select
          value={statusFilter}
          onChange={handleSetStatus}
          items={STATUS_ITEMS}
        />
      </StatusContainer>
      <TableResponsiveWrapper>
        <TableResponsive>
          <TableHeader template={columns}>
            <TableColumnTitle>Capacity Commitment Id</TableColumnTitle>
            <TableColumnTitle>Provider Name</TableColumnTitle>
            <TableColumnTitleWithSort
              order={orderType}
              field="expiresIn"
              isActive={orderBy === 'expiresIn'}
              onSort={handleSort}
              hint="The period during which the CC will remain active and earn rewards, unless it fails earlier"
            >
              Expiration
            </TableColumnTitleWithSort>
            <TableColumnTitleWithSort
              order={orderType}
              field="stakingReward"
              isActive={orderBy === 'stakingReward'}
              onSort={handleSort}
              hint="The share of rewards the staker earns for Capacity Commitment, defined by provider"
            >
              Staking Reward
            </TableColumnTitleWithSort>
            <StyledHint content="The current status of the CC">
              <TableColumnTitle>Status</TableColumnTitle>
            </StyledHint>
          </TableHeader>
          <Accordion.Root type="multiple" value={isAccordionOpen}>
            <TableBody
              skeletonCount={CAPACITY_COMMITMENTS_PER_PAGE}
              skeletonHeight={40}
              isLoading={isLoading}
              isEmpty={pageCapacityCommitments?.length === 0}
              noDataView={
                <MessageContainer>
                  <MessageBlock>
                    <Text color="black900" size={18}>
                      {stakerId &&
                      statusFilter === 'all' &&
                      Object.keys(filters).length <= 0
                        ? 'You haven’t staked any Capacity Commitments yet'
                        : 'Not found Capacity Commitments'}
                    </Text>
                  </MessageBlock>
                </MessageContainer>
              }
            >
              {pageCapacityCommitments?.map((capacityCommitment) =>
                renderRow({ capacityCommitment, onExpand, columns }),
              )}
            </TableBody>
          </Accordion.Root>
        </TableResponsive>
      </TableResponsiveWrapper>
      {capacityCommitments && (
        <TableFooter
          countItems={ccPerPage}
          onSetCountItems={setCCPerPage}
          pages={getTotalPages(capacityCommitments.length)}
          page={page}
          hasNextPage={hasNextPage}
          onSelect={selectPage}
        />
      )}
    </Flex>
  )
}
