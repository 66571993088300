import React from 'react'
import styled from '@emotion/styled'
import { formatDistanceStrict } from 'date-fns'

import { CloseIcon } from '../../assets/icons'
import { useApiQuery } from '../../hooks'
import formatPercent from '../../utils/formatPercent'
import { formatUnixTimestamp } from '../../utils/formatUnixTimestamp'
import { getShortAddress } from '../../utils/getShortAddress'

import { colors } from '../../constants/colors'
import { Button } from '../Button'
import ContractButton from '../ContractButton'
import HintedText from '../HintedText'
import { Text } from '../Text'
import TokenBalance from '../TokenBalance'

import { BaseModal } from './BaseModal'
import { Close } from './styled'
import { Warning } from './Warning'

interface StakeModalProps {
  isVisible: boolean
  id: string
  collateral: bigint
  duration: number
  expectedAPR: string
  onClose?: () => void
  onStake?: () => void
}

export const StakeModal: React.FC<StakeModalProps> = ({
  isVisible,
  onStake,
  onClose,
  id,
  collateral,
  duration,
  expectedAPR,
}) => {
  const { data: period } = useApiQuery(async (client) => {
    const epoch = await client.currentEpoch()
    const epochDuration = client.getEpochDuration()
    const start = client.calculateTimestamp(epoch + 1)

    return {
      start,
      end: start + duration * epochDuration,
    }
  }, [])

  const capacityCommitmentId = getShortAddress(id || '', 12)

  const handleClose = () => {
    onClose?.()
  }

  const handleStake = () => {
    onStake?.()
  }

  return (
    <BaseModal isVisible={isVisible} maxWidth={440} onClose={onClose}>
      <ModalRoot>
        <ModalHeader>
          <InfoTextContainer>
            <Text size={20} color="grey500">
              You are about to stake
              <BadgeContainer>
                <TokenBalance size="big" balance={collateral} />
              </BadgeContainer>
              in capacity commitment&nbsp;
            </Text>
            <Text size={14} color="grey400">
              ID: {capacityCommitmentId}
            </Text>
          </InfoTextContainer>
          <Close>
            <CloseIconStyled onClick={onClose} />
          </Close>
        </ModalHeader>
        <Divider />
        <ModalContent>
          <ParametersContainer>
            {period && (
              <>
                <InfoRow>
                  <InfoRow>
                    <HintedText
                      text="Staking duration"
                      hint="How long the staked funds will be locked"
                    />
                  </InfoRow>
                  <Text size={12}>
                    {formatDistanceStrict(
                      period.start * 1000,
                      period.end * 1000,
                    )}
                  </Text>
                </InfoRow>

                <InfoRow>
                  <InfoRow>
                    <HintedText text="Capacity commitment start" />
                  </InfoRow>
                  <Text size={12}>
                    {formatUnixTimestamp(period.start).date}
                  </Text>
                </InfoRow>
                <InfoRow>
                  <InfoRow>
                    <HintedText text="Expected unlock date" />
                  </InfoRow>
                  <Text size={12}>{formatUnixTimestamp(period.end).date}</Text>
                </InfoRow>
              </>
            )}
            <InfoRow>
              <InfoRow>
                <HintedText
                  text="Expected apr"
                  hint="Estimated annual percentage return based on target reward per CU per epoch"
                />
              </InfoRow>
              <Text size={12}>
                {expectedAPR === undefined
                  ? '—'
                  : `${formatPercent(expectedAPR)}%`}
              </Text>
            </InfoRow>
          </ParametersContainer>
          <Warning>
            Immediately after you stake FLT tokens, you will not be able to
            withdraw them from the Capacity commitment until it ends.
          </Warning>
          <ButtonsContainer>
            <Button variant="grey" isLarge onClick={handleClose}>
              Cancel
            </Button>
            <ContractButton
              threshold={collateral}
              variant="black"
              isLarge
              onClick={handleStake}
            >
              Stake
            </ContractButton>
          </ButtonsContainer>
        </ModalContent>
      </ModalRoot>
    </BaseModal>
  )
}

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 32px;
  flex-grow: 1;
`

const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 32px;
`

const ModalRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10;

  @media (max-width: 500px) {
    min-height: 100vh;
  }
`

const CloseIconStyled = styled(CloseIcon)`
  color: ${colors.grey400};
  cursor: pointer;
`

const Divider = styled.div`
  border: 1px solid ${colors.grey100};
  width: 100%;
`

const InfoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  & > ${Text} {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
`

const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  width: min-content;
  gap: 8px;
  margin: 0 10px;
`

const ParametersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: space-between;
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;

  & > button:last-of-type {
    width: 100%;
  }

  @media (max-width: 768px) {
    margin-top: auto;
    flex-direction: column;
    align-self: flex-end;
  }
`
