import React, { lazy, Suspense } from 'react'
import { Route } from 'wouter'

import { Layout } from './components/Layout'

import { MainPage } from './pages/main'
import { ROUTES } from './constants'

import 'react-loading-skeleton/dist/skeleton.css'
import 'normalize.css'
import '@rainbow-me/rainbowkit/styles.css'
import './App.css'

const CapacityConfigJson = lazy(() =>
  import('./pages/config').then((module) => ({
    default: module.CapacityConfigJson,
  })),
)
const ConfigJson = lazy(() =>
  import('./pages/config').then((module) => ({ default: module.ConfigJson })),
)

const LoadingFallback = () => <div>Loading...</div>

export const App: React.FC = () => {
  return (
    <Layout>
      <Suspense fallback={<LoadingFallback />}>
        <Route path={ROUTES.stake} component={MainPage} />
        <Route path={ROUTES.config} component={ConfigJson} />
        <Route path={ROUTES.capacity} component={CapacityConfigJson} />
      </Suspense>
    </Layout>
  )
}
