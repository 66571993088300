import { Flex } from 'reflexbox'

import { getShortAddress } from '../../utils/getShortAddress'

import { VITE_EXPLORER_URL } from '../../constants/config'
import { CapacityCommitment } from '../../store/CapacityCommitment'
import { A } from '../A'
import { Copyable } from '../Copyable'
import HintedText from '../HintedText'
import { Text } from '../Text'

import { CapacityCommitmentInformation, InformationRow } from './styled'

export function CUsStats({
  capacityCommitment,
}: {
  capacityCommitment: CapacityCommitment
}) {
  return (
    <CapacityCommitmentInformation>
      <Text size={16}>Statistics</Text>
      <InformationRow>
        <HintedText
          text="Total CUs"
          hint="Number of Compute Units (CU) in this Capacity Commitment (CC)"
        />
        <Text size={12}>{capacityCommitment.totalCU}</Text>
      </InformationRow>
      <InformationRow>
        <HintedText
          text="Staked at"
          hint="The date when this Capacity Commitment was staked"
        />
        <Text size={12}>{capacityCommitment.startTime}</Text>
      </InformationRow>
      <InformationRow>
        <HintedText
          text="Expiration"
          hint="The period during which the CC will remain active and earn rewards, unless it fails earlier"
        />
        <Text size={12}>{capacityCommitment.endTime}</Text>
      </InformationRow>
      <InformationRow>
        <HintedText
          text="Missed proofs / Threshold"
          hint="How many times this CC’s CUs failed to submit Proofs; How many failures are allowed before it's considered failed"
        />
        <Text size={12}>
          {capacityCommitment.slashedProofs?.toString()} /{' '}
          {capacityCommitment.totalPossibleProofs?.toString()}
        </Text>
      </InformationRow>
      <InformationRow>
        <HintedText text="Commitment ID" />
        <Flex alignItems="center">
          <A
            size={12}
            href={`${VITE_EXPLORER_URL}/capacity/${capacityCommitment.id}`}
          >
            {getShortAddress(capacityCommitment.id, 16)}
          </A>
          <Copyable value={capacityCommitment.id} />
        </Flex>
      </InformationRow>
    </CapacityCommitmentInformation>
  )
}
