import { useContext } from 'react'

import { CapacityCommitment } from '../../store/CapacityCommitment'
import HintedText from '../HintedText'
import { SignerContext } from '../SignerProvider'
import { Text } from '../Text'
import TokenBalance from '../TokenBalance'
import WithdrawCollateralButton from '../Withdraw/WithdrawCollateralButton'

import { ActionButtonContainer } from './styled'
import { CapacityCommitmentInformation, InformationRow } from './styled'

export function Collateral({
  capacityCommitment,
}: {
  capacityCommitment: CapacityCommitment
}) {
  const { signer } = useContext(SignerContext)

  return (
    <CapacityCommitmentInformation>
      <Text size={16}>Collateral</Text>
      <InformationRow>
        <HintedText
          text="Staked collateral"
          hint="The amount of FLT tokens staked for this Capacity Commitment"
        />
        <TokenBalance balance={capacityCommitment.stakedCollateral ?? 0n} />
      </InformationRow>
      <InformationRow>
        <HintedText
          text="Current collateral"
          hint={
            <>
              The current value of the collateral. Equals to the staked one if
              not slashed.{' '}
              <strong>
                May be zero for already ended CCs with collateral withdrawn
              </strong>
            </>
          }
        />
        <TokenBalance
          balance={
            capacityCommitment.collateralWithdrawn
              ? 0n
              : (capacityCommitment.currentCollateral ?? 0n)
          }
        />
      </InformationRow>
      {signer && (
        <ActionButtonContainer>
          <WithdrawCollateralButton capacityCommitment={capacityCommitment} />
        </ActionButtonContainer>
      )}
    </CapacityCommitmentInformation>
  )
}
