import { Endpoint } from '@rest-hooks/rest'
import { JsonRpcSigner } from 'ethers'

import { renderJsonString } from '../utils/renderJsonString'

import { CapacityCommitment } from './CapacityCommitment'
import { Contracts } from '@fluencelabs/deal-ts-clients'
import { DEPLOYMENT } from '../constants/config'

export const withdrawCollateralCapacityCommitment = new Endpoint(
  async (
    {
      capacityCommitment,
      signer,
    }: {
      capacityCommitment: CapacityCommitment
      signer: JsonRpcSigner
    },
    body: Partial<CapacityCommitment>,
  ) => {
    const defaultResult = {
      id: capacityCommitment.id,
    }

    if (
      capacityCommitment.status !== 'failed' &&
      capacityCommitment.status !== 'inactive'
    )
      return defaultResult

    const contracts = new Contracts(signer, DEPLOYMENT)

    const tx = await contracts.diamond.withdrawCollateral(
      capacityCommitment.id,
      {
        maxPriorityFeePerGas: 0,
      },
    )

    await tx.wait()

    return {
      id: capacityCommitment.id,
      ...body,
    }
  },
  {
    key: (params: unknown) =>
      `withdrawCollateralCapacityCommitment ${renderJsonString(params)}`,
    name: 'withdrawCollateralCapacityCommitment',
    schema: CapacityCommitment,
  },
)
